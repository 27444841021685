/*
 * @Author: your name
 * @Date: 2020-11-20 11:02:54
 * @LastEditTime: 2020-11-20 11:17:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\documentManagement\mixin.js
 */
import { documentService } from "@/api/documentManagement";

export default {
  methods: {
    queryRulesClassifyTree(id) {
      return new Promise((resolve, reject) => {
        try {
          documentService.queryRulesClassifyTree({ id }).then((resp) => {
            resolve(resp);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    /**
     * @description: 指定节点是否为叶子节点
     * @param data 节点数据
     * @return:
     */
    isLeaf(data) {
      return data.hasChildren === "001";
    },
    /**
     * @description: 树 异步加载子节点
     * @param node
     * @param resolve
     */
    async loadNode(node, resolve) {
      const { data } = node;
      const { hasChildren, id } = data;
      if (hasChildren === "000") {
        // 有子项
        const rsp = await documentService.queryRulesClassifyTree({ id });
        resolve(rsp);
      } else {
        resolve([]);
      }
    }
  }
};

<template>
  <div class="useDocEntry">
    <div class="doc-content">
      <div class="equal-side">
        <div class="equal-sidetopbar">
          <div class="pull-left">
            <div class="col-tit">
              文档管理
            </div>
          </div>
          <div class="pull-right" v-if="accessAddClass || accessAddFile">
            <el-dropdown>
              <span class="el-dropdown-link">
                <en-icon name="tianjia-danchuang" size="16px" class="adddocIcon" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item  class='dropdown-right-item' @click.native="addClassify" v-if="accessAddClass">
                  新增分类
                </el-dropdown-item>
                <el-dropdown-item  class='dropdown-right-item' @click.native="addFile" v-if="accessAddFile">
                  新增文档
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="equal-sidecentbar" v-en-loading="menuLoading">
          <en-file-select-board
            :data="menuData"
            node-key="id"
            highlight-current
            ref="filtmenu"
            accordion
            expand-icon="wendangleixing-wendangguanli"
            collpase-icon="wendangleixing-wendangguanli"
            :props="defaultProps"
            @check="selectItem"
            @node-click="selectItem"
            @node-select="selectItem"
            :render-content="renderContent"
          >
          </en-file-select-board>
        </div>
      </div>
      <div class="equal-cent">
        <div class="equal-topbar">
          <div class="pull-left">
            <div class="col-tit">
              {{ curFileMenu.name }}
            </div>
          </div>
          <div class="pull-right">
            <en-search class="col-tit" type="text" placeholder="请输入关键词搜索" v-model="searchkey" clearable @search="handleSearch" @change="handleChange" :showBorder="true"> </en-search>
          </div>
        </div>
        <div class="equal-main" v-en-loading="enLoading">
          <en-table :data="tableData" ref="doctable" :height-config="{ bottomHeight: 15 }" :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }">
            <en-table-column type="index" width="60" align="center"></en-table-column>
            <en-table-column title="文档名称" prop="name" width="240">
              <template slot-scope="scope">
                <div class="file-name">
                  <en-icon name="fujian-tongyong" size="small" color="#3e90fe"></en-icon>
                  <span>{{ scope.row.name }}</span>
                  <en-icon name="guding-wendangguanli" size="12px" color="#49bef2" style="margin-left:10px" v-if="scope.row.top === '000'"></en-icon>
                </div>
              </template>
            </en-table-column>
            <en-table-column title="发布者" field="deptName" width="170"> </en-table-column>
            <en-table-column title="发布时间" field="modifyTime" width="200">
              <template slot-scope="scope">
                {{ scope.row.modifyTime | formatDate }}
              </template>
            </en-table-column>
            <!-- <en-table-column title="概要信息" field="msg"></en-table-column> -->
            <en-table-column title="附件" field="attr">
              <template slot-scope="scope">
                <div class="appendix-btn" @click="appendix(scope.row)">附件({{ scope.row.attr.length }})</div>
              </template>
            </en-table-column>
            <template #hoverRow="{row, rowIndex}" >
              <en-expand-buttons :min-number="3" :data="getButtonData(row)" v-if="btnLoading" :key="row.id" @button-click="handleButtonClick(row, rowIndex, arguments[1], arguments[0])" />
            </template>
          </en-table>
          <!-- <en-result v-else type="NoData"></en-result> -->
        </div>
      </div>
    </div>
    <docAddClass ref="addClass" :classify-item="classifyItem" @success="classifySuccess"></docAddClass>
    <docAppendix ref="docAppendix" :docInfo="docInfo"></docAppendix>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { request } from "en-js";
import { documentService } from "@/api/documentManagement";
// import menuList from "../components/treeMenu.vue";
import { randomNum } from "@/tools/util";
import docAddClass from "../alert/docAddClass.vue"; // 分类弹窗
import docAppendix from "../alert/docAppendix"; // 附件列表
// 附件弹窗
export default {
  name: "document",
  filters: {
    formatDate(value) {
      const date = new Date(value);
      const y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? `0${MM}` : MM;
      let d = date.getDate();
      d = d < 10 ? `0${d}` : d;
      let h = date.getHours();
      h = h < 10 ? `0${h}` : h;
      let m = date.getMinutes();
      m = m < 10 ? `0${m}` : m;
      let s = date.getSeconds();
      s = s < 10 ? `0${s}` : s;
      return `${y}-${MM}-${d} ${h}:${m}:${s}`;
    }
  },
  // props: {
  //   treeData: Array
  // },
  components: {
    docAddClass,
    docAppendix
  },
  data() {
    return {
      btnLoading: true,
      curFileMenu: {
        // 保存当前操作的文件夹信息
        name: ""
      },
      searchkey: "",
      // 是否为编辑分类
      checkAll: false,
      // 左侧菜单tree
      defaultProps: {
        children: "nodes",
        label: "name",
        value: "id",
        disabled: true
      },
      menuData: [], // 左侧菜单树
      // 分页
      pageModel: {
        pageSize: 10,
        pageNo: 1,
        total: null,
        totalPages: null
      },
      // 文件列表
      docInfo: [],
      enLoading: null,
      menuLoading: null,
      tableData: [
        // {
        //   filename: "请假规则",
        //   deptName: "人力资源部",
        //   name: "王小虎",
        //   pulishtime: "2016-05-02 11:00",
        //   msg: "公司考勤制度是还是ish会死his灰红色i",
        //   appendix: "附件(1)"
        // }
      ],
      classifyItem: {}, // 编辑分类数据
      // 分类按钮 依次是删除、查看、编辑、新增
      classifyBtn: ["277521935179448320", "277521872751427584", "277521900586926080", "277521846914514944"],
      // 文档按钮 依次是新增、查看、编辑、删除、置顶
      documentBtn: ["277521665534984192", "277521705786671104", "277521740506071040", "277521773347471360", "277521984370245632"],
      accessAddFile: false,
      accessAddClass: false
    };
  },
  mounted() {
    this.queryRulessAccess();
  },
  methods: {
    // 按权限分配按钮
    getButtonData({ top, btnList = "" }) {
      const btnLis = [];
      if (btnList.indexOf("277521740506071040") > -1) {
        btnLis.push({
          name: "编辑",
          icon: "bianji-liebiao",
          key: "edit",
          iconColor: "#26c393"
        });
      }
      if (btnList.indexOf("277521773347471360") > -1) {
        btnLis.push({
          name: "删除",
          icon: "shibai",
          key: "delete",
          iconColor: "#f76b6b"
        });
      }
      if (btnList.indexOf("277521984370245632") > -1) {
        btnLis.push({
          name: top === "000" ? "取消置顶" : "置顶",
          key: "top",
          icon: "zhiding-wendangguanli",
          iconColor: "#3e90fe"
        });
      }
      return btnLis;
    },
    renderContent(h, { node, data }) {
      return (
        <span class="custom-tree-node " onMouseleave={(event) => this.toggleMenu(event, data, false)}>
          <span class="label">{node.label}</span>
          <div class="pull-right btns " v-show={data.accessEditClass || data.accessDelClass}>
            <en-icon
              name="bianji1"
              class="btns-icon"
              nativeOnMouseover={(event) => {
                this.toggleMenu(event, data, true);
              }}
            ></en-icon>
            <en-collapse-area>
              <div
                class="btns-list-container"
                v-show={data.showMenu}
                onMouseleave={(event) => {
                  this.toggleMenu(event, data, false);
                }}
              >
                <div class="btns-list">
                  <div
                    class="btns-item"
                    v-show={data.accessEditClass}
                    onClick={(event) => {
                      this.addClassify(event, data);
                    }}
                  >
                    编辑
                  </div>
                  <div
                    v-show={data.accessDelClass}
                    class="btns-item"
                    onClick={(event) => {
                      this.deleteClassIfy(event, data);
                    }}
                  >
                    删除
                  </div>
                </div>
              </div>
            </en-collapse-area>
          </div>
        </span>
      );
    },
    toggleMenu(event, data, flag) {
      event.stopPropagation();
      this.$set(data, "showMenu", flag);
    },
    selectMenu() {},
    // 搜索
    handleSearch() {
      this.queryDocList(this.curFileMenu.id, 1, this.pageModel.pageSize, this.searchkey);
    },
    handleChange: debounce(function a(key) {
      this.handleSearch(key);
    }, 500),
    @request(true, "menuLoading", {
      subTitle: "正在加载中..."
    })
    async queryRulessAccess() {
      // 查询添加分类、添加文档权限
      const arr = await documentService.queryRulessAccess();
      if (arr) {
        arr.forEach((item) => {
          if (item.id.indexOf("277521846914514944") > -1) {
            this.accessAddClass = item.id.indexOf("277521846914514944") > -1;
          } else if (item.id.indexOf("277521665534984192") > -1) {
            this.accessAddFile = item.id.indexOf("277521665534984192") > -1;
          }
        });
      }
      this.queryMenu();
    },
    async queryMenu() {
      async function recursionFetch(id) {
        const arr = await documentService.queryRulesClassify({
          id
        });
        const result = arr.slice();
        const promises = [];
        const fileIconColorList = ["#2c76bd", "#20b789", "#ec5353", "#6c7df5", "#efbe11"];
        result.forEach((item) => {
          item.iconColor = item.iconColor || fileIconColorList[randomNum(0, 4)]; // 随机生成文件夹得iconColor颜色
          if ("btnList" in item) {
            item.accessEditClass = item.btnList.indexOf("277521900586926080") > -1;
            item.accessDelClass = item.btnList.indexOf("277521935179448320") > -1;
          }
          if (item.hasChildren === "000") {
            promises.push(
              (async() => {
                const childNodes = await recursionFetch(item.id);
                item.nodes = childNodes.reduce((acc, cItem) => {
                  cItem.parentName = item.name;
                 if ("btnList" in cItem) {
                  cItem.accessEditClass = cItem.btnList.indexOf("277521900586926080") > -1;
                  cItem.accessDelClass = cItem.btnList.indexOf("277521935179448320") > -1;
                 }
                  if ("btnList" in cItem) {
                    if (cItem.btnList.indexOf("277521872751427584") > -1) {
                    acc.push(cItem);
                  }
                  }
                  return acc;
                }, []);
              })()
            );
          }
        });
        await Promise.all(promises);
        return result;
      }
      try {
        this.loading = true;
        const docList = await recursionFetch();
        // this.menuData = docList.filter((item) => item.btnList.indexOf("277521872751427584") > -1);
        this.menuData = docList;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    // 新增分类成功回调
    async classifySuccess() {
      await this.queryMenu();
      // const parentNode = this.$refs.filtmenu.$children[0].getNode(resp.parentId);
      // this.$refs.filtmenu.$children[0].append(resp, parentNode);
      // TODO 如果需要即时刷新左侧文件夹目录需接口返回当前新增目录的信息 包括id  name  tenantId  目前仅能强刷，强刷可能会导致文件夹选择状态改变
    },
    @request(true, "enLoading", {
      subTitle: "正在加载中..."
    })
    async queryDocList(id, pageNo = this.pageModel.pageNo, pageSize = this.pageModel.pageSize, keys = this.searchkey) {
      try {
        this.loading = true;
        const res = await documentService.queryRules({
          classifyId: id,
          pageNo,
          pageSize,
          name: keys
        });
        this.tableData = res.records.filter((item) => item.btnList.indexOf("277521705786671104") > -1);
        this.pageModel.total = res.total;
        this.pageModel.totalPages = res.totalPages;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    // 置顶
    async topping(row, index) {
      try {
        this.loading = true;
        const top = row.top === "001" ? "000" : "001";
        await documentService.topRules({
          id: row.id,
          classifyId: row.classifyId,
          top
        });
        row.top = top;
        // this.handleSearch();//
        this.$message.success(`${top === "000" ? "置顶" : "取消置顶"}成功`);//! 因为重新从服务起获取列表会刷新页面 所以这里前端手动更改状态
        console.log(this.tableData, index);
        if (top === "000") {
          this.btnLoading = false;
          this.tableData.splice(index, 1);
          this.tableData.unshift(row);
          setTimeout(() => {
            this.btnLoading = true;// fixme hack  vue组件复用导致en-expand-buttons组件在操作行数据后无法及时渲染导致数据和视图对应不上的bug
          }, 200);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    // 删除文档
    delectDocs(row) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async() => {
        await documentService.deleteRules({ id: row.id });
        this.handleSearch();
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    },
    /**
     * @description: 点击左侧菜单获取文档列表
     * @param {type} classifyId
     * @return {type}
     */
    selectItem(row) {
      console.log(row);
      this.curFileMenu = row;
      this.queryDocList(row.id);
    },
    /**
     *@description 翻页事件
     * @param {pageNo,pageSize}
     * @return:
     */
    handlePageChanged(data) {
      Object.assign(this.pageModel, data);
      // 调用查询列表数据方法
      this.queryDocList(this.curFileMenu.id, data.pageNo, data.pageSize, this.searchkey);
    },

    /**
     * @description 表格行按钮点击
     * @param {row, index, btnData, btnIndex}
     * @return:
     */
    handleButtonClick(row, index, btnData) {
      // 编辑
      if (btnData.key === "edit") {
        // const cacheDocInfo = pick(row, ["view", "id", "classifyId", "deptId", "attr", "deptName", "name", "tenantId"]);
        sessionStorage.setItem(`doc#${row.id}`, JSON.stringify(row)); // 存储当前需要操作的文档信息  用于编辑页面获取
        // TODO 改成通过后台接口获取
        this.editDocs(row.id);
      }
      // 置顶
      if (btnData.key === "top") {
        this.topping(row, index);
      }
      // 删除
      if (btnData.key === "delete") {
        this.delectDocs(row);
      }
    },

    /**
     *@description 附件列表
     *@param  row
     */
    appendix(row) {
      this.docInfo = row;
      this.$refs.docAppendix.visiable = true;
    },

    /**
     * 表格筛选方法
     */
    handleTableFilterChange() {
      // this.$message("我是en-table绑定方法实现");
    },
    /**
     * 新增文档
     */
    addFile() {
      this.$router.push({
        path: "/document-management/addDoc/add"
      });
    },
    /**
     * @description: 编辑文档
     * @param {*} DID 文档id
     * @return {*} void
     */
    editDocs(DID) {
      this.$router.push({
        path: "/document-management/addDoc/edit",
        query: {
          id: DID
        }
      });
    },
    /**
     * 新增/编辑 分类
     */
    addClassify(event, item) {
      event.stopPropagation();
      if (item) {
        this.classifyItem = { ...item };
      } else {
        this.classifyItem = {};
      }
      this.$refs.addClass.visiable = true;
    },
    deleteClassIfy(event, item) {
      event.stopPropagation();
      this.$confirm("你确定要删除该分类吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async() => {
        await documentService.deleteRulesClassify({ id: item.id });
        this.queryMenu();
        if (this.curFileMenu.id === item.id) {
          this.curFileMenu = {};
          this.handleSearch();
        }
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    },
    /**
     * 加签人
     */
    signClick() {
      this.$refs.signer.signerVisible = true;
    }
  },
  activated() {
    this.handleSearch();
  }
};
</script>

<style lang="scss" scoped>
.useDocEntry {
  // background: #1d2431;
  .adddocIcon {
    cursor: pointer;
  }
  .doc-content {
    height: 100%;
  }
  .pull-left {
    float: left;
  }
  .pull-right {
    float: right;
  }
  .col-tit {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
  .equal-sidecentbar {
    padding: 20px 20px 80px;
    overflow-y: scroll;
    height: 100%;
    // 菜单区域样式
    /deep/ .en-file-select-board-container .tree-node-level-1 {
      background: white;
      border: 1px solid #e8ecf2;
      border-radius: 4px;
      margin-bottom: 10px;
    }
    /deep/ .en-file-select-board-container .tree-node-level-1.is-expanded > .el-tree-node__content {
      background-color: #3e90fe !important;
      color: white !important;
      padding-left: 10px !important;
      border-radius: 4px;
      .el-tree-node__expand-icon > .en-icon {
        color: #fff !important;
      }
      &:hover {
        .el-dropdown-link {
          color: #fff;
        }
      }
    }
    /deep/ .el-tree-node__children {
      overflow: unset;
    }
    /deep/ .custom-tree-node {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      .label {
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
      }
      .btns {
        width: 30px;
        flex: 0;
        color: inherit;
      }
      .btns-icon {
        width: 16px;
        height: 16px;
        color: #3e90fe;
        display: none;
      }

      .btns-list-container {
        position: absolute;
        right: -13px;
        top: 16px;
        z-index: 9999;
        .btns-list {
          width: 120px;
          background-color: #ffffff;
          border: 1px solid #e8ecf2;
          box-shadow: 0 5px 5px 0 rgba(236, 236, 236, 0.75);
          border-radius: 4px;
          margin-top: 17px;

          .btns-item {
            color: #636c78;
            cursor: pointer;
            line-height: 32px;
            font-size: 12px;
            padding-left: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              background-color: #3e90fe;
              color: #ffffff;
            }
          }
        }
      }
    }
    /deep/ .el-tree-node__content:hover {
      .custom-tree-node .btns {
        visibility: visible;
      }
      .btns-icon {
        display: block !important;
      }
    }
  }
  .equal-side {
    width: 285px;
    border-radius: 5px;
    background: #fff;
    height: 100%;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    .equal-sidetopbar {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      border-bottom: 1px solid #e8ecf2;

      .en-icon {
        color: #3e90fe;
      }
    }
  }
  .equal-topbar {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #e8ecf2;
  }
  .equal-cent {
    border-radius: 5px;
    background: #fff;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 295px);
  }
  .equal-main {
    position: relative;
    height: 93%;
    clear: both;
    .en-icon {
      // color: #3e90fe;
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
    }
    .bottom {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-top: 1px solid #e8ecf2;
    }
  }
  & /deep/ .el-dropdown {
    height: 24px;
  }
  & /deep/ .el-tree-node__label {
    text-align: left;
  }
  & /deep/ .el-dialog /deep/ .el-dialog__header {
    text-align: left;
  }
  & /deep/ .el-select {
    width: 100%;
  }
  & /deep/ .en-color-setting {
    float: left;
    margin-top: 4px;
  }
  & /deep/ .dialog-footer {
    .el-button {
      height: 32px;
      width: 72px;
      background: #3e90fe;
    }
  }
  .file-name {
    span {
      margin-left: 5px;
    }
  }
  .appendix-btn {
    cursor: pointer;
    color: #3e90fe;
  }
  /deep/ .el-tree {
    .el-tree-node__label {
      font-size: 12px;
    }
  }
}
.dropdown-right-item:focus, .dropdown-right-item:not(.is-disabled):hover{
    background: #4896e0;
    color: #fff;
  }
</style>

<!--
 * @Author: 周晴龙
 * @Date: 2020-05-29 10:37:12
 * @LastEditTime: 2021-09-07 11:04:51
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\documentManagement\alert\docAppendix.vue
-->
<template>
  <en-dialog
      title="文档附件"
      :visible.sync="visiable"
      class="docAppendix"
      width="1100px"
      min-height="200px"
    >
      <div class="checkmain">
        <en-table :data="docInfo.attr" @select-change="handleSelectChange" max-height="550">
            <en-table-column type="index-selection" width="60"></en-table-column>
            <en-table-column title="文档名称" field="name">
              <!--自定义单元格-->
            <template v-slot="{ row}">
              <div  @click="previewFile(row)">
                <en-icon
                :name="suffix(row.suffix).iconName"
                :color="suffix(row.suffix).iconColor"
                size="small"
              ></en-icon>
              <span style="display:inline-block;margin-left: 4px;position: relative;top:-3px;">{{ row.name }}</span>
              </div>

            </template>
            </en-table-column>
        </en-table>
      </div>
      <div slot="footer">
        <en-button @click="multipleDownload" :loading='loading'>
          下载
        </en-button>
      </div>
    </en-dialog>
</template>

<script>
import { getFileTypeList } from "@/tools/folderVarCommon.js";
import { downFileApi } from "@/api/downFileApi";
import { xhrDownload, openOfficeOnline } from "@/tools/util";
import { documentService } from "@/api/documentManagement";
//
//  downFileByUrl,
export default {
  name: "docAppendix",
  props: {
    docInfo: {} // 附件列表
  },
  data() {
    return {
      loading: false, // 是否正在下载
      visiable: false,
      selRow: [] // 多选中的行内容
    };
  },
  mounted() {
    console.log("收到了哦", this.docInfo.attr);
  },
  methods: {
    /*
     * @description: 列表选中
     * @param {type}
     * @return:
    */
    handleSelectChange({ selection }) {
      this.selIds = selection.map((item) => item.id);
      this.selRow = selection;
      console.log(this.selRow);
    },
    /**
     * @description: 下载
     * @param {row}
     * @return:
     */
    download(row) {
      row.forEach((item) => {
        window.open(item.url);
      });
    },
    previewFile(item) {
      console.log(item);
      let operType = 1;
      const suffix = item.suffix.toLowerCase();
      if (["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(suffix)) {
        // 这4种格式文件仅支持查看
        if (["doc", "ppt", "xls", "pdf"].includes(suffix)) {
          operType = 1;
        }
        documentService.saveFileLog({
          typeName: "查看",
          fileType: "附件",
          content: item.name
        });
        openOfficeOnline(operType, item.suffix, item.fileCode);
      } else {
        this.$message.warning("暂不支持该类型");
      }
    },
    /**
     * @description:多选下载
     * @param {type}
     * @return:
     */
    async multipleDownload() {
      let downParams = {};
      if (this.selRow.length === 0) {
        this.$message({
          message: "请选择下载文件！",
          type: "warning"
        });
        return;
      } if (this.selRow.length === 1) {
         documentService.saveFileLog({
          typeName: "下载",
          fileType: "附件",
          content: this.selRow[0].name
        });
        xhrDownload(this.selRow[0].url, (error) => {
          this.$message.error(error);
        });
        // downFileByIFrame(this.selRow[0].url);
      } else {
        downParams = { id: this.docInfo.id, type: 1 };
        try {
          this.loading = true;
          documentService.saveFileLog({
            typeName: "批量下载",
            fileType: "文档",
            content: this.docInfo.name
          });
          await downFileApi.batchUpLoadFileZip(downParams);
          this.$message({
            type: "success",
            message: "下载成功！"
          });
        } catch (err) {
          this.$message({
            type: "error",
            message: err
          });
        } finally {
          this.loading = false;
        }
      }
    },
    /**
     * @description: 根据后缀给相应icon和颜色
     * @param {arr, suffix}
     * @return:
     */
    findIconType(arr, suffix) {
      let iconName;
      let iconColor;
      arr.forEach((el) => {
        if (el.text === suffix) {
          iconName = el.iconName;
          iconColor = el.iconColor;
        }
      });
      return { iconName, iconColor };
    },
    /**
     * @description: 根据后缀给相应icon
     * @param {name}
     * @return:
     */
    suffix(name) {
      const fileList = getFileTypeList();
      const filetype = this.findIconType(fileList, name);
      return filetype;
    }
  }
};
</script>

<style lang="scss" scoped>
.docAppendix{
  text-align: left;
  /deep/ .el-dialog__body{
    max-height: 600px;
    min-height: 260px;
  }
  .el-dialog__footer{
    .en-button{
      width: 72px;
      height: 32px;
      line-height: 32px;
    }
  }
}
.checkmain {
  .file-item {
    display: flex;
    background: #fbfbfb;
    border: 1px solid #e8ecf2;
    padding: 7px 10px;
    border-radius: 5px;
    .en-icon {
      margin-right: 10px;
      color: #527ce7;
    }
    .txts {
      flex: 1;
    }
  }
  .file-lis {
    min-height: 300px;
    .el-checkbox {
      margin-top: 10px;
    }
  }
  .file-name{
    .en-icon{
      margin-right: 10px;
    }
  }
  .file-lis {
    .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;
    }
    & /deep/ .el-checkbox {
      display: flex;
      width: 49%;
      min-width: 49%;
      margin-right: 15px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      & /deep/ .el-checkbox__inner{
        margin-top: 8px;
      }
      & /deep/ .el-checkbox__input {
        line-height: 34px;
      }
      & /deep/ .el-checkbox__label {
        flex: 1;
        vertical-align: middle;
      }
    }
  }

}
</style>

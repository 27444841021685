var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "docAppendix",
      attrs: {
        title: "文档附件",
        visible: _vm.visiable,
        width: "1100px",
        "min-height": "200px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visiable = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "checkmain" },
        [
          _c(
            "en-table",
            {
              attrs: { data: _vm.docInfo.attr, "max-height": "550" },
              on: { "select-change": _vm.handleSelectChange },
            },
            [
              _c("en-table-column", {
                attrs: { type: "index-selection", width: "60" },
              }),
              _c("en-table-column", {
                attrs: { title: "文档名称", field: "name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.previewFile(row)
                              },
                            },
                          },
                          [
                            _c("en-icon", {
                              attrs: {
                                name: _vm.suffix(row.suffix).iconName,
                                color: _vm.suffix(row.suffix).iconColor,
                                size: "small",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  "margin-left": "4px",
                                  position: "relative",
                                  top: "-3px",
                                },
                              },
                              [_vm._v(_vm._s(row.name))]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "en-button",
            {
              attrs: { loading: _vm.loading },
              on: { click: _vm.multipleDownload },
            },
            [_vm._v(" 下载 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
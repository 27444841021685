var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "addDoc" }, [
    _c(
      "div",
      { staticClass: "doc-main" },
      [
        _c("en-title-card", {
          attrs: {
            name: `${_vm.$route.params.type == "add" ? "新增" : "修改"}文档`,
            "need-back": "true",
          },
          on: { back: _vm.back },
        }),
        _c(
          "div",
          { staticClass: "equal-main" },
          [
            _c(
              "el-form",
              {
                ref: "editform",
                attrs: {
                  rules: _vm.rules,
                  model: _vm.form,
                  "label-width": "80px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "文档名称", prop: "name" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入名称" },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "所属分类", prop: "classifyId" } },
                  [
                    _c("en-select", {
                      attrs: {
                        mode: "tree",
                        "data-mode": "value",
                        placeholder: "请选择所属分类",
                        props: {
                          value: "id",
                          label: "name",
                          isLeaf: _vm.isLeaf,
                        },
                        data: _vm.superiorList,
                        "check-limit": 1,
                        "expand-on-click-node": false,
                        "check-on-click-node": true,
                        load: _vm.loadNode,
                        lazy: "",
                      },
                      model: {
                        value: _vm.form.classifyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "classifyId", $$v)
                        },
                        expression: "form.classifyId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "发文部门", prop: "deptId" } },
                  [
                    _c("en-select", {
                      attrs: {
                        mode: "tree",
                        "data-mode": "id-name",
                        placeholder: "请选择发文部门",
                        props: {
                          value: "id",
                          label: "name",
                          children: "nodes",
                        },
                        data: _vm.departmentList,
                        "check-limit": 1,
                        "expand-on-click-node": false,
                        "check-on-click-node": true,
                      },
                      on: { change: _vm.departmentChange },
                      model: {
                        value: _vm.form.deptId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "deptId", $$v)
                        },
                        expression: "form.deptId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "查看范围" } },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom-start",
                          width: "100%",
                          offset: 0,
                          "popper-class": "doc-popcss",
                          trigger: "click",
                        },
                      },
                      [
                        _c("en-tree", {
                          attrs: {
                            data: _vm.treeList,
                            "node-key": "id",
                            "highlight-current": "",
                            "current-node-key": _vm.viewRangeType,
                            props: _vm.defaultProps,
                            singleSelect: true,
                          },
                          on: { "node-click": _vm.changeRelease },
                        }),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.viewRangeType === "002",
                                expression: "viewRangeType === '002'",
                              },
                            ],
                            staticStyle: { overflow: "auto" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "输入关键字进行过滤" },
                              model: {
                                value: _vm.filterText,
                                callback: function ($$v) {
                                  _vm.filterText = $$v
                                },
                                expression: "filterText",
                              },
                            }),
                            _c("en-tree", {
                              ref: "tree_dept",
                              attrs: {
                                data: _vm.treeData,
                                "default-checked-keys": _vm.defaultCheckedKeys,
                                "show-checkbox": "",
                                "node-key": "id",
                                lazy: "",
                                load: _vm.loadN,
                                "highlight-current": "",
                                props: _vm.defaultProps,
                                "filter-text": _vm.filterText,
                              },
                              on: { "check-change": _vm.changeMulti },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              border: "1px solid #e8ecf2",
                              "border-radius": "5px",
                              width: "100%",
                              height: "34px",
                              padding: "0 10px",
                              cursor: "pointer",
                            },
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _vm.viewRangeType === "000"
                              ? _c("span", { staticClass: "user-mod" }, [
                                  _vm._v("公开"),
                                ])
                              : _vm._e(),
                            _vm.viewRangeType === "001"
                              ? _c("span", { staticClass: "user-mod" }, [
                                  _vm._v("本部门"),
                                ])
                              : _vm._e(),
                            _vm.form.view.rangeList.length &&
                            _vm.viewRangeType === "002"
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.form.view.rangeList,
                                    function (notice) {
                                      return _c(
                                        "span",
                                        {
                                          key: notice.id,
                                          staticClass: "user-mod",
                                        },
                                        [_vm._v("@" + _vm._s(notice.name))]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "block-item", attrs: { label: "选择文档" } },
                  [
                    _c(
                      "en-upload",
                      { attrs: { "on-change": _vm.handleOnChange } },
                      [
                        _c("en-icon", {
                          staticStyle: { "margin-top": "7px" },
                          attrs: {
                            name: "icontianjia-danchuang",
                            size: "16",
                            color: "#4793de",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "addfile-cent" }, [
                  _c(
                    "div",
                    { staticClass: "file-lis" },
                    _vm._l(_vm.form.attr, function (fileItem, ind) {
                      return _c(
                        "div",
                        { key: fileItem.url, staticClass: "file-item" },
                        [
                          _c("en-icon", {
                            staticClass: "icon-doc",
                            attrs: {
                              name: _vm.suffix(fileItem.name).iconName,
                              color: _vm.suffix(fileItem.name).iconColor,
                              size: "small",
                            },
                          }),
                          _c("div", { staticClass: "txts" }, [
                            _vm._v(" " + _vm._s(fileItem.name) + " "),
                          ]),
                          _c("en-icon", {
                            staticClass: "close",
                            attrs: { name: "guanbi-danchuang", size: "10" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.deleteFile(_vm.form.attr, ind)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bottom" },
          [
            _c(
              "en-button",
              { staticClass: "save-btn", on: { click: _vm.submit } },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!--
 * @Author: 周晴龙
 * @Date: 2020-05-29 10:24:52
 * @LastEditTime: 2021-08-10 10:35:43
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\documentManagement\alert\docAddClass.vue
-->
<template>
    <en-dialog
      :visible.sync="visiable"
      :title="`${classifyItem.id?'编辑':'新增'}分类`"
      width="700px"
      class="add-class"
      @close="visiable=false"
    >
      <el-form
        ref="formClass"
        label-width="80px"
        :rules="rules"
        :model="formClass"
      >
        <el-form-item label="分类名称" prop="className">
          <el-input v-model="formClass.className" placeholder="请输入分类名称"/>
        </el-form-item>
        <el-form-item label="所属上级" >
          <en-select
          mode="tree"
          v-model="superiorId"
          data-mode="data"
          placeholder="请选择"
          :props="{ value: 'id', label: 'name', isLeaf: isLeaf }"
          :data="superiorList"
          :check-limit="1"
          :expand-on-click-node="false"
          :check-on-click-node="true"
          :load="loadNode"
          lazy
          @change="selectChange"
        >
        </en-select>
        </el-form-item>
        <!-- <el-form-item label="颜色设置">
          <en-color-setting
          v-model="color"
          class="en-color-setting"
        />
        </el-form-item> -->
      </el-form>
      <div slot="footer">
        <en-button @click="submit(classifyItem.id?'editRulesClassify':'addRulesClassify')">
          保存
        </en-button>
      </div>
    </en-dialog>
</template>

<script>
import { documentService } from "@/api/documentManagement";

export default {
  props: {

    // isEdit: {
    //   type: Boolean,
    //   default: false
    // },
    classifyItem: {}
  },
  data() {
    return {
      visiable: false,
      formClass: { className: "", parentId: "0", color: "" },
      // 所属上级
      superiorList: [],
      superiorId: null,
      color: "#26c393",
      rules: {
        className: [
          { required: true, message: "请输入分类名称", trigger: "blur" }
        ]
      }
    };
  },
  watch: {
    visiable(val) {
      this.queryRulesClassifyTree(0);
      if (val) {
        this.formClass.className = this.classifyItem.name;
        this.formClass.parentId = this.classifyItem.parentId;
        this.formClass.id = this.classifyItem.id;
        if (this.classifyItem?.parentId && this.classifyItem.parentId !== "0") {
          this.superiorId = {
            id: this.classifyItem.parentId || undefined,
            name: this.classifyItem.parentName || undefined
          };
        } else {
          this.superiorId = null;
        }
      }
    }
  },
  methods: {
    // 查询所属上级
    async queryRulesClassifyTree(id) {
      const res = await documentService.queryRulesClassifyTree({ id });
      res.forEach((item) => {
        if ("btnList" in item) {
          item.disabled = item.btnList.indexOf("277521872751427584") === -1;
        }
      });
      this.superiorList = res;
    },

    /**
     * @description: 指定节点是否为叶子节点
     * @param data 节点数据
     * @return:
     */
    isLeaf(data) {
      return data.hasChildren === "001";
    },
    /**
     * @description: 树 异步加载子节点
     * @param node
     * @param resolve
     */
    async loadNode(node, resolve) {
      const { data } = node;
      const { hasChildren, id } = data;
      if (hasChildren === "000") {
        // 有子项
        const rsp = await documentService.queryRulesClassifyTree({ id });
        rsp.forEach((item) => {
          item.disabled = item.btnList.indexOf("277521872751427584") === -1;
        });
        resolve(rsp);
      } else {
        resolve([]);
      }
    },
    selectChange(val) {
      console.log(val);
      this.formClass.parentId = val.id;
    },
    // 提交
    /**
     * @description: 新增&修改文档分类
     * @param {*} type:editRulesClassify修改 addRulesClassify:新增
     * @return {*}
     */
     submit(type) {
      this.$refs.formClass.validate(async(valid) => {
          if (valid) {
            try {
              this.loading = true;
              const rsp = await documentService[type]({
                name: this.formClass.className,
                parentId: this.formClass.parentId || 0,
                id: this.classifyItem.id || undefined
              });
              this.$message.success(`${type === "addRulesClassify" ? "新增" : "修改"}分类成功`);
              this.$emit("success", rsp);
              this.visiable = false;
            } catch (e) {
              this.visiable = true;
              throw e;
            } finally {
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.add-class{
  text-align: left;
  /deep/ .el-dialog__body{
    min-height:350px;
  }
  .el-select{
    width: 100%;
  }
  .el-form{
    margin-top: 10px;
  }
  .el-form-item{
    margin-bottom: 10px;
  }
  .el-dialog__footer{
    .en-button{
      width: 72px;
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>

<template>
  <div class="addDoc">
    <div class="doc-main">
      <en-title-card :name="`${$route.params.type == 'add' ? '新增' : '修改'}文档`" need-back="true" @back="back"> </en-title-card>
      <div class="equal-main">
        <el-form ref="editform" :rules="rules" :model="form" label-width="80px">
          <el-form-item label="文档名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="所属分类" prop="classifyId">
            <en-select
              mode="tree"
              v-model="form.classifyId"
              data-mode="value"
              placeholder="请选择所属分类"
              :props="{ value: 'id', label: 'name', isLeaf: isLeaf }"
              :data="superiorList"
              :check-limit="1"
              :expand-on-click-node="false"
              :check-on-click-node="true"
              :load="loadNode"
              lazy
            ></en-select>
          </el-form-item>
          <el-form-item label="发文部门" prop="deptId">
            <en-select
              mode="tree"
              v-model="form.deptId"
              data-mode="id-name"
              placeholder="请选择发文部门"
              :props="{ value: 'id', label: 'name', children: 'nodes' }"
              :data="departmentList"
              :check-limit="1"
              :expand-on-click-node="false"
              :check-on-click-node="true"
              @change="departmentChange"
            ></en-select>
          </el-form-item>
          <!-- <el-form-item label="查看范围" class="col-flex">
            <el-select placeholder="请选择查看范围" v-model="viewRangeType">
              <el-option label="公开" value="000"></el-option>
              <el-option label="本部门" value="001"></el-option>
              <el-option label="@" value="002"></el-option>
            </el-select>
            <div class="select-btn" @click="selPerson()" v-show="viewRangeType == '002'">
              <en-icon name="xuanzerenyuan-wendangguanli" size="small"></en-icon>
              <span>选择成员</span>
            </div>
          </el-form-item> -->
          <el-form-item label="查看范围">
            <el-popover placement="bottom-start" width="100%" :offset="0" popper-class="doc-popcss" trigger="click">
              <en-tree :data="treeList" node-key="id" highlight-current :current-node-key="viewRangeType" :props="defaultProps" :singleSelect="true" @node-click="changeRelease"> </en-tree>
              <div v-show="viewRangeType === '002'" style="overflow:auto">
                <el-input placeholder="输入关键字进行过滤" v-model="filterText"> </el-input>
                <en-tree
                  ref="tree_dept"
                  :data="treeData"
                  :default-checked-keys="defaultCheckedKeys"
                  show-checkbox
                  node-key="id"
                  lazy
                  :load="loadN"
                  highlight-current
                  :props="defaultProps"
                  @check-change="changeMulti"
                  :filter-text="filterText"
                >
                </en-tree>
              </div>
              <div slot="reference" style="border: 1px solid #e8ecf2;border-radius:5px;width:100%;height:34px;padding: 0 10px;cursor:pointer;" >
                <span v-if="viewRangeType === '000'" class="user-mod">公开</span>
                <span v-if="viewRangeType === '001'" class="user-mod">本部门</span>
                <div v-if="form.view.rangeList.length && viewRangeType === '002'">
                  <span class="user-mod" v-for="notice in form.view.rangeList" :key="notice.id">@{{ notice.name }}</span>
                </div>

              </div>
            </el-popover>
          </el-form-item>
          <!-- <el-form-item
              label="概要信息"
              prop="desc"
              class="block-item"
            >
              <el-input type="textarea"></el-input>
            </el-form-item> -->
          <el-form-item label="选择文档" class="block-item">
            <en-upload :on-change="handleOnChange">
              <en-icon name="icontianjia-danchuang" size="16" color="#4793de" style="margin-top: 7px;"></en-icon>
            </en-upload>
          </el-form-item>
          <div class="addfile-cent">
            <!-- <en-upload :on-change="handleOnChange">
                <el-button size="small" type="primary" class="el-icon-plus">
                  添加
                </el-button>
              </en-upload> -->
            <div class="file-lis">
              <div class="file-item" v-for="(fileItem, ind) in form.attr" :key="fileItem.url">
                <en-icon :name="suffix(fileItem.name).iconName" :color="suffix(fileItem.name).iconColor" size="small" class="icon-doc"></en-icon>
                <div class="txts">
                  {{ fileItem.name }}
                </div>
                <en-icon name="guanbi-danchuang" size="10" class="close" @click.native="deleteFile(form.attr, ind)"> </en-icon>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="bottom">
        <en-button class="save-btn" @click="submit">保存</en-button>
      </div>
    </div>
    <!-- <choose-person ref="choosePerson" @selectChange="chooseSelect" :initData="form.view.rangeList"></choose-person> -->
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { documentService } from "@/api/documentManagement";
import { settingService } from "@/api";
// import { uploadService } from "@/api/common";
import folderComm from "@/mixins/myFolder/folderComm.js"; // 获取同步的树,模板分类
import docMixin from "../mixin";
// import choosePerson from "../alert/choosePerson.vue";
// 选择人员
export default {
  // components: { choosePerson },
  name: "AddDoc",
  mixins: [docMixin, folderComm],
  data() {
    return {
      fileIconType: {
        xlsx: "fujian-excel"
      },
      curClassify: null,
      curDept: null,
      fileList: [],
      superiorList: [], // 所属分类列表
      departmentList: [], // 发文部门列表
      selectData: [],
      defaultCheckedKeys: [],
      viewRangeType: "000",
      filterText: "",
      noticeList: [],
      treeData: [],
      treeList: [
        {
          id: "000",
          name: "公开"
        },
        {
          id: "001",
          name: "本部门"
        },
        {
          id: "002",
          name: "@"
        }
      ],
      defaultProps: {
        value: "id",
        label: "name",
        children: "nodes",
        isLeaf: this.isLeaf
      },
      form: {
        view: {
          // 文档查看范围  默认 公开：000
          rangeList: [],
          viewType: "000"
        },
        name: "",
        classifyId: "",
        deptId: "",
        deptName: "",
        attr: []
      },
      rules: {
        name: [{ required: true, message: "请输入文档名称", trigger: "blur" }],
        classifyId: [{ required: true, message: "请选择所属分类", trigger: "change" }],
        deptId: [
          {
            required: true,
            message: "请选择发文部门",
            trigger: "change"
          }
        ],
        attr: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个文档附件",
            trigger: "change"
          }
        ]
      },
      value: ""
    };
  },
  created() {
    // mixin方法
    this.queryRulesClassifyTree(0).then((res) => {
      res.forEach((item) => {
        if ("btnList" in item) {
          item.disabled = item.btnList.indexOf("277521872751427584") === -1;
        }
      });
      this.superiorList = res;
    });
    this.queryDeptUserTree(0);
    this.getDeptUserTree(0);
    this.$route.query.id ? this.readerDocInfo() : undefined;
  },
  methods: {
    /**
     * @description: 树 异步加载子节点
     * @param node
     * @param resolve
     */
    async loadNode(node, resolve) {
      const { data } = node;
      const { hasChildren, id } = data;
      if (hasChildren === "000") {
        // 有子项
        const res = await documentService.queryRulesClassifyTree({ id });
        res.forEach((item) => {
          item.disabled = item.btnList.indexOf("277521872751427584") === -1;
        });
        resolve(res);
      } else {
        resolve([]);
      }
    },
    changeMulti() {
      const ns = this.$refs.tree_dept.getCheckedNodes(false, true);
      // this.noticeList = ns;
      this.form.view.rangeList = ns;
    },
    changeRelease(data) {
      const { id } = data;
      this.viewRangeType = id;
    },
    isLeaf(data) {
      return data.hasChildren === "001";
    },
    async loadN(node, resolve) {
      const { data } = node;
      const { hasChildren, id } = data;
      if (hasChildren === "000") {
        // 有子项
        const rsp = await settingService.queryDeptUserTree({ id });
        resolve(rsp);
      } else {
        resolve([]);
      }
    },
    async queryDeptUserTree(id) {
      const resp = await settingService.queryDeptUserTree({ id });
      this.treeData = resp;
    },
    departmentChange(data) {
      // const obj = this.departmentList.find((item) => item.id === data.id);
      this.form.deptId = data.id;
      this.form.deptName = data.name;
    },
    // 编辑时获取文档信息
    readerDocInfo() {
      this.form = JSON.parse(sessionStorage.getItem(`doc#${this.$route.query.id}`));
      this.form?.view?.rangeList?.forEach((item) => {
        item.id = item.refId;
        this.defaultCheckedKeys.push(item.id);
      });
      this.viewRangeType = this.form?.view?.viewType;
    },
    // 重置表单校验、数据
    resetForm() {
      this.curClassify = null;
      this.curDept = null;
      this.form = {
        view: {
          // 文档查看范围  默认 公开：000
          rangeList: [],
          viewType: "000"
        },
        name: "",
        classifyId: "",
        deptId: "",
        deptName: "",
        attr: []
      };
      this.$nextTick(() => {
        this.$refs.editform?.resetFields();
      });
    },
    // 删除附件
    deleteFile(list, index) {
      list.splice(index, 1);
    },
    // 查询所属上级
    async getDeptUserTree(id) {
      const res = await documentService.queryDeptTreeAll({ id });
      this.departmentList = res;
    },
    handleOnChange(data) {
      if (data.progress) {
        this.form.attr.push({
          name: data.file.name,
          suffix: data.file.extension,
          fileSize: data.file.size,
          url: data.file.code
        });
      }
    },
    /**
     * @description: 树 异步加载子节点
     * @param node
     * @param resolve
     */
    async loadDeptNode(node, resolve) {
      const { data } = node;
      const { hasChildren, id } = data;
      if (hasChildren === "000") {
        // 有子项
        const rsp = await documentService.queryDeptTreeAll({ id });
        resolve(rsp);
      } else {
        resolve([]);
      }
    },

    // chooseSelect(data) {
    //   this.form.view.rangeList = data;
    // },
    // 新增文档
    async fileSubmit(type) {
      this.form.view.viewType = this.viewRangeType;
      const submitData = cloneDeep(this.form);
      submitData.view = JSON.stringify(submitData.view);
      submitData.attr = JSON.stringify(submitData.attr);
      console.log("submitData", type, submitData);
      await documentService[type](submitData);
      this.$message.success(type === "addRulesDocs" ? "新增成功" : "修改成功");
      this.resetForm();
      this.$router.push({
        path: "/document-management/home"
      });
    },
    submit() {
      this.$refs.editform.validate((valid) => {
        if (valid) {
          this.fileSubmit(this.$route.params.type === "add" ? "addRulesDocs" : "editRulesDocs");
        }
      });
    },

    /**
     * 文件删除
     */
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    /**
     * 文件预览
     */
    handlePreview(file) {
      console.log(file);
    },
    /**
     * 文件选择
     */
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    onSuccess(response, file) {
      console.log(file);
    },
    /**
     * 返回
     */
    back() {
      this.$router.go(-1);
    }
    /**
     * 选择人员
     */
    // selPerson() {
    //   this.$refs.choosePerson.dialogVisible = true;
    // }
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem(`doc#${this.$route.query.id}`);
    next();
  }
};
</script>
<style lang="scss">
.doc-popcss{
  padding:0;
  .el-tree{
    max-height: 300px;
    overflow: auto;
  }
}
</style>
<style lang="scss" scoped>
.addDoc {
  background: #fff;
  .doc-main {
    position: relative;
    height: 100%;
    .bottom {
      background: #fff;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 64px;
      line-height: 64px;
      border-top: 1px solid #e8ecf2;
      text-align: right;
      .save-btn {
        margin-right: 20px;
      }
    }
  }
  .el-button {
    height: 32px;
    width: 72px;
    font-size: 12px;
    padding: 0;
    font-weight: 400;
  }
  .col-flex {
    & /deep/ .el-form-item__content {
      display: flex;
    }
    & /deep/ .el-input--suffix {
      margin-right: 20px;
    }
  }
  .el-tree {
    margin-top: 10px;
  }
  .el-tree-node__content {
    height: 32px;
  }
  .en-transfer .en-transfer-panel {
    padding: 10px;
  }
  .select-btn {
    width: 100px;
    margin-left: 10px;
    cursor: pointer;
    .en-icon {
      color: #409eff;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .pull-left {
    float: left;
  }
  .col-tit {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
  .equal-topbar {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #e8ecf2;
  }
  .equal-main {
    padding: 20px 20px;
    height: calc(100% - 134px);
    overflow-y: auto;
  }
  .el-form-item {
    display: block;
    width: 48%;
    float: left;
    margin-right: 20px;
  }
  .block-item {
    width: 98%;
    min-width: 600px;
    display: block;
    text-align: left;
  }
  .el-select {
    width: 100%;
  }
  .addfile-cent {
    padding: 15px;
    line-height: 1.5;
    border-radius: 4px;
    float: left;
    width: 100%;
    background: #f6f7fb;
  }
  & /deep/ .el-upload-list {
    display: flex;
    flex-wrap: wrap;
    .el-upload-list__item {
      // width: 390px;
      width: 31%;
      display: flex;
      background: #fbfbfb;
      line-height: 20px;
      border: 1px solid #e8ecf2;
      padding: 7px 10px;
      border-radius: 5px;
      margin-top: 10px;
      margin-right: 1%;
      background: #f5f7fa;
      border: 1px solid #e8ecf2;
      .el-icon-upload-success {
        font-size: 18px;
      }
      & /deep/ .el-upload-list__item-status-label {
        top: 8px;
      }
      .el-icon-close {
        font-size: 18px;
        top: 10px;
      }
      & /deep/ .el-icon-close-tip {
        top: 10px;
      }
    }
  }
  .file-lis {
    display: flex;
    flex-wrap: wrap;
    .file-item {
      display: flex;
      line-height: 20px;
      border: 1px solid #e8ecf2;
      padding: 7px 10px;
      border-radius: 5px;
      margin:5px;
      margin-right: 1%;
      background: #fff;
      border: 1px solid #e8ecf2;
      font-size: 12px;
      flex: 0 1 31.9%;
      .txts {
        flex: 1;
      }
      &:hover {
        .close {
          visibility: visible;
        }
      }
    }
    .icon-doc {
      margin-right: 10px;
    }

    .close {
      margin-top: 4px;
      visibility: hidden;
      cursor: pointer;
    }
  }
  .assign-basic-data {
    text-align: left;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "add-class",
      attrs: {
        visible: _vm.visiable,
        title: `${_vm.classifyItem.id ? "编辑" : "新增"}分类`,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visiable = $event
        },
        close: function ($event) {
          _vm.visiable = false
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formClass",
          attrs: {
            "label-width": "80px",
            rules: _vm.rules,
            model: _vm.formClass,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "分类名称", prop: "className" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入分类名称" },
                model: {
                  value: _vm.formClass.className,
                  callback: function ($$v) {
                    _vm.$set(_vm.formClass, "className", $$v)
                  },
                  expression: "formClass.className",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属上级" } },
            [
              _c("en-select", {
                attrs: {
                  mode: "tree",
                  "data-mode": "data",
                  placeholder: "请选择",
                  props: { value: "id", label: "name", isLeaf: _vm.isLeaf },
                  data: _vm.superiorList,
                  "check-limit": 1,
                  "expand-on-click-node": false,
                  "check-on-click-node": true,
                  load: _vm.loadNode,
                  lazy: "",
                },
                on: { change: _vm.selectChange },
                model: {
                  value: _vm.superiorId,
                  callback: function ($$v) {
                    _vm.superiorId = $$v
                  },
                  expression: "superiorId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "en-button",
            {
              on: {
                click: function ($event) {
                  return _vm.submit(
                    _vm.classifyItem.id
                      ? "editRulesClassify"
                      : "addRulesClassify"
                  )
                },
              },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }